import consumer from "./consumer";

let currentUserId = document.querySelector("body").getAttribute("data-current-user-id");

window.subscribeToImporterProgressChannel = function (currentUserId) {
    if (!consumer.subscriptions.findAll('ImporterProgressChannel', currentUserId).length) {
        consumer.subscriptions.create({channel: "ImporterProgressChannel", user_id: currentUserId}, {

            connected() {
                // Called when the subscription is ready for use on the server
                console.log("connected to ImporterProgressChannel")
            },

            disconnected() {
                // Called when the subscription has been terminated by the server
            },

            received(data) {
                // Called when there's incoming data on the websocket for this channel
                const feedbackElement = $("#importer-process-feedback").closest("pre");
                const progressElement = $("#importer-progressbar");
                const isScrolledToBottom = feedbackElement.scrollTop() + feedbackElement.innerHeight() >= feedbackElement[0].scrollHeight;

                if (data.step) {
                    feedbackElement.append('<br>' + data.step);

                    // Scroll to the bottom if already at the bottom before appending new content
                    if (isScrolledToBottom) {
                        feedbackElement.scrollTop(feedbackElement[0].scrollHeight);
                    }
                }

                if (data.progress) {
                    console.log(data.progress);
                    progressElement.css("width", data.progress + '%');
                    progressElement.text(data.progress + '%');
                }

                if (data.done) {
                    feedbackElement.append('<br>' + data.done);
                    $("#importer-loading").addClass("d-none");
                    if (isScrolledToBottom) {
                        feedbackElement.scrollTop(feedbackElement[0].scrollHeight);
                    }
                }
            }
        });
    }
}